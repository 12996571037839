import React, { useEffect, useState } from "react";
import "./AppointmentCard.css";
import { DateTime } from "luxon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import ActivityPage from "../ActivityPage";
import { ToastContainer, toast } from "react-toastify";
import { Navigate, useNavigate, Link } from "react-router-dom";
import moment from "moment-timezone";
import axios from "axios";
import { BASE_URL } from "../../apiConfig";

function AppointmentCard(props) {
  console.log(props.data);
  const navigate = useNavigate();

  const [action, setAction] = useState(false);
  const [activity, setActivity] = useState(false);

  const handleClick = (status, type, patientId) => {
    if (status === "upcoming" && type !== "therapist") {
      setAction(!action);
    } else if (type !== "therapist" && status === "past") {
      navigate(`/therapist/patient/patient-detail/${patientId}`, {
        state: { isEdit: true },
      });
    } else {
      navigate("/patient/progress");
    }
  };

  function appointmentCardClicked(id) {
    if (props.type === "therapist") {
      navigate(`/patient/therapist/therapist-detail/${id}`);
    } else {
      navigate(`/therapist/patient/patient-detail/${id}`, {
        state: { isEdit: true },
      });
    }
  }

  const [therapist, setTherapist] = useState();
  useEffect(() => {
    const fetchTherapist = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/therapist/details`, {
          withCredentials: true,
        });
        const therapistObj = response.data.therapist;
        setTherapist(therapistObj);

        console.log(therapistObj);
      } catch (error) {
        console.error("Error fetching therapist:", error);
      }
    };

    fetchTherapist();
  }, []);

  async function createCalendarEvent(
    slotId,
    therapistId,
    patientId,
    date,
    year,
    startTime,
    endTime
  ) {
    console.log(slotId, therapistId, patientId, date, year, startTime, endTime);
    try {
      const response = await axios.post(`${BASE_URL}/therapist/create-event`, {
        slotId,
        therapistId,
        patientId,
        date,
        year,
        startTime,
        endTime,
      });
      if (response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error creating meet link!");
      const { errorName, errorDesc, errorCode } = error?.response?.data;
      if (
        errorName === "invalid_grant" ||
        errorCode === 400 ||
        error?.response?.status === 400
      ) {
        handleGoogleAuth();
      }
    }
  }

  const CLIENT_ID =
    "125139459857-2qoo7u4q15u29hip89j7b2dicb1r7rtv.apps.googleusercontent.com";

  function handleGoogleAuth() {
    // Create the Google Identity Services script dynamically
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;

    // Load the script and initialize Google OAuth client
    script.onload = async () => {
      // Use window.google to ensure it is globally available
      const codeClient = await window.google.accounts.oauth2.initCodeClient({
        client_id: CLIENT_ID,
        scope: "https://www.googleapis.com/auth/calendar",
        ux_mode: "popup",
        callback: (response) => {
          const code = response.code;
          const code_receiver_uri = `${BASE_URL}/therapist/googleAuthFlow`;

          // Send auth code to your backend
          fetch(code_receiver_uri, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "X-Requested-With": "XmlHttpRequest",
            },
            body: `code=${code}`,
            credentials: "include",
          })
            .then((response) => response.text())
            .then((data) => {
              console.log("Auth code response: " + data);
            })
            .catch((error) => {
              console.error("Error in fetch:", error);
            });
        },
      });

      // Trigger the OAuth flow
      codeClient.requestCode();
    };

    // Append the script to the document body
    document.body.appendChild(script);
  }

  // console.log(props.data?.patient?.profilePic);
  // const { currentYear, date, startTime, endTime } = props.data;

  // function convertToYYYYMMDD(dateStr, currentYear) {
  //   const date = new Date(`${dateStr} ${currentYear}`); // Create a new date with the specified year
  //   const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with zero
  //   const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed) and pad with zero

  //   return `${currentYear}-${month}-${day}`; // Format as YYYY-MM-DD
  // }

  // console.log({ startTime, endTime });
  // console.log(convertToYYYYMMDD(date, currentYear)); // Log the formatted date

  // const startDateTime = DateTime.fromISO(
  //   `${convertToYYYYMMDD(date, currentYear)}T${startTime}`,
  //   {
  //     zone: props.data.patient.timeZone, // Ensure this is a valid timezone string
  //   }
  // );
  // const endDateTime = DateTime.fromISO(
  //   `${convertToYYYYMMDD(date, currentYear)}T${endTime}`,
  //   {
  //     zone: props.data.patient.timeZone,
  //   }
  // );

  // console.log({ startDateTime, endDateTime });

  const { currentYear, date, startTime, endTime } = props.data;
  console.log(props.data);
  // Function to convert a date string to YYYY-MM-DD format
  function convertToYYYYMMDD(dateStr, currentYear) {
    const date = new Date(`${dateStr} ${currentYear}`); // Create a new date with the specified year
    const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with zero
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed) and pad with zero

    return `${currentYear}-${month}-${day}`; // Format as YYYY-MM-DD
  }

  // Therapist timezone from the data
  const therapistTimeZone = props?.data?.timezone || "Asia/Kolkata"; // Make sure this is defined
  const patientTimeZone =
    props.type === "patient"
      ? therapist?.timezone
      : props.data.patient.timeZone; // Ensure this is defined
  console.log({ patientTimeZone });

  // Format the date for ISO
  const formattedDate = convertToYYYYMMDD(date, currentYear);

  // Create DateTime objects in the therapist's timezone
  const startDateTime = DateTime.fromISO(`${formattedDate}T${startTime}`, {
    zone: therapistTimeZone,
  });

  const endDateTime = DateTime.fromISO(`${formattedDate}T${endTime}`, {
    zone: therapistTimeZone,
  });

  // Convert the DateTime objects to the patient's timezone
  const startDateTimeInPatientTZ = startDateTime.setZone(patientTimeZone);
  const endDateTimeInPatientTZ = endDateTime.setZone(patientTimeZone);

  // Log the results
  console.log({
    startDateTimeInPatientTZ:
      startDateTimeInPatientTZ.toFormat("dd-MM-yyyy HH:mm"), // Example formatting
    endDateTimeInPatientTZ: endDateTimeInPatientTZ.toFormat("dd-MM-yyyy HH:mm"),
  });

  const start = startDateTimeInPatientTZ
    .toFormat("dd-MM-yyyy HH:mm")
    .split(" ");
  const end = endDateTimeInPatientTZ.toFormat("dd-MM-yyyy HH:mm").split(" ");

  console.log({ props });
  return (
    <>
      <div
        className="row  appointmentCard align-items-center"
        style={{ padding: "0 10px" }}
      >
        <ToastContainer />
        <div className="col col-xs-12 col-sm-12  col-md-6  col-lg-5  imgContent">
          <div
            className="therapistImgDiv"
            onClick={() =>
              appointmentCardClicked(
                props.type === "patient"
                  ? props.data.patient._id
                  : props.data.therapist._id
              )
            }
          >
            {props.type === "patient" &&
              (props.data?.patient?.profilePic === null ? (
                <img
                  className="therapistImg"
                  src={require("../../images/person.jpg")}
                  alt="appimg"
                />
              ) : (
                <img
                  className="therapistImg"
                  src={
                    `${BASE_URL}/upload/${props?.data?.patient?.profilePic}` ||
                    require("../../images/person.jpg")
                  }
                />
              ))}
            {/* { */}
            {props.type === "therapist" &&
              (!props.data?.therapist?.profilePic ? (
                <img
                  className="therapistImg"
                  src={require("../../images/person.jpg")}
                  alt="appimg"
                />
              ) : (
                <img
                  className="therapistImg"
                  src={`${BASE_URL}/upload/${props.data?.therapist?.profilePic}`}
                  alt="appimg"
                />
              ))}
            {/* <img
              className="therapistImg"
              src={require("../../images/person.jpg")}
              alt="appimg"
            /> */}
          </div>
          <div className="therapistContent">
            <h5
              onClick={() =>
                appointmentCardClicked(
                  props.type === "patient"
                    ? props.data?.patient?._id
                    : props.data?.therapist?._id
                )
              }
            >
              {props.type === "patient"
                ? props.data.patient?.studentName
                : props.data.therapist?.fullName}
            </h5>
            <p>
              {props.type !== "patient"
                ? `${props.data.patient?.country} ${props.data.patient?.timeZone}`
                : `${therapist?.country} (${therapist?.timezone})`}
            </p>
            {props.type === "therapist" && (
              <p style={{ display: "flex", alignItems: "center" }}>
                <BusinessCenterOutlinedIcon sx={{ marginRight: "5px" }} />{" "}
                {props.data.therapist?.yearsOfExp}+ years
              </p>
            )}

            {props.type === "patient" && (
              <p>{`${props.data.patient.age} years old`}</p>
            )}
          </div>
        </div>
        <div
          className="col  col-xs-12 col-sm-12 col-md-5  col-lg-6 row btnGroup "
          id="btngrp"
        >
          <div className="dateTime-btn col-12 ">
            <p>
              <DateRangeIcon style={{ fill: "#7457fb" }} /> {`${start[0]}`}
            </p>
            <p>
              <AccessTimeOutlinedIcon style={{ fill: "#f27d58" }} />{" "}
              {`${start[1]}-${end[1]}`}
            </p>
          </div>

          <div className="row col-12 bottomBtnDiv justify-content-between">
            <div
              className={
                props.status === "upcoming"
                  ? "action-btn col-5"
                  : "action-btn col-12"
              }
              onClick={() =>
                handleClick(props.status, props.type, props.data.patient._id)
              }
            >
              {props.status === "upcoming" && props.type !== "therapist"
                ? "Action"
                : "Activity"}{" "}
              {props.status === "upcoming" && props.type !== "therapist" && (
                <KeyboardArrowDownIcon />
              )}
              {action && !props.data.googleMeetLink && (
                <div className="action-div">
                  <h6
                    onClick={() =>
                      createCalendarEvent(
                        props.data._id,
                        props.data.therapist,
                        props.data.patient._id,
                        props.data.date,
                        props.data.currentYear,
                        props.data.startTime,
                        props.data.endTime
                      )
                    }
                  >
                    Add to calendar & generate meet link
                  </h6>
                </div>
              )}
            </div>

            {props.status === "upcoming" && (
              <div
                className="join-btn col-6"
                style={{
                  backgroundColor: !props.data.googleMeetLink && "lightgray",
                }}
              >
                {props.data.googleMeetLink ? (
                  <Link
                    to={props.data.googleMeetLink}
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      width: "100%",
                      textAlign: "center",
                      pointerEvents: "auto",
                    }}
                  >
                    {props.status === "upcoming" ? "Join" : "Recording"}
                  </Link>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: "gray",
                      cursor: "auto",
                    }}
                  >
                    {props.status === "upcoming" ? "Join" : "Recording"}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AppointmentCard;
