import React, { useState } from "react";
import "./AppointmentCard.css";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import { Navigate, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../../apiConfig";

function EnquiryCard(props) {
  const navigate = useNavigate();

  async function handleApproveTherapist(therapistId) {
    try {
      const response = await axios.post(
        `${BASE_URL}/admin/approve-therapist`,
        {
          therapistId: therapistId,
        },
        { withCredentials: true }
      );

      console.log(response.data);

      if (response.data.success) {
        toast.success("Therapist has been approved!");
      } else {
        toast.error("Therapist not found!");
      }
    } catch (error) {
      toast.error("An error occurred while approving therapist");
    }
  }

  function appointmentCardClicked() {
    navigate("/therapist/patient/patient-detail");
  }
  function gototherapistdetail(therapistId) {
    navigate(`/admin/therapist/therapist-detail/${therapistId}`);
  }

  console.log(props.data);

  return (
    <>
      <div className="row appointmentCard" style={{ cursor: "pointer" }}>
        <ToastContainer />
        <div
          className="col col-xs-12 col-md-5  imgContent"
          onClick={() => gototherapistdetail(props.data._id)}
        >
          <div className="therapistImgDiv">
            {!props.data.profilePic ? (
              <img
                className="therapistImg"
                src={require("../../images/person.jpg")}
                alt="appimg"
              />
            ) : (
              <img
                className="therapistImg"
                src={`${BASE_URL}/upload/${props.data.profilePic}`}
                alt="appimg"
              />
            )}
            {/* <img
              className="therapistImg"
              src={require("../../images/person.jpg")}
              alt="appimg"
            /> */}
          </div>
          <div className="therapistContent">
            <h5>{props.data.fullName}</h5>
            <p>{`${props.data.country}`}</p>
            <p>
              <BusinessCenterOutlinedIcon /> {props.data.yearsOfExp}+ years
            </p>
            {/* {props.admin&&<p><BusinessCenterOutlinedIcon /> 10+ years</p>} */}
          </div>
        </div>
        <div className="col col-xs-12 col-md-6 row btnGroup ">
          <div className="dateTime-btn col-11 col-sm-12">
            {/* {props.therapist ? ( */}
            <p onClick={() => gototherapistdetail(props.data._id)}>
              View Profile
            </p>
            {/* ) : (
              <>
                <p>
                  <DateRangeIcon style={{ fill: "#7457fb" }} /> 19 July 2023
                </p>
                <p>
                  <AccessTimeOutlinedIcon style={{ fill: "#f27d58" }} /> 12:00
                  PM
                </p>
              </>
            )} */}
          </div>

          <div
            className="row col-12 bottomBtnDiv"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              style={{ color: "#fff" }}
              className="action-btn approve-btn col-5"
              onClick={() => handleApproveTherapist(props.data._id)}
            >
              Approve
            </div>

            {/* <div className=" join-btn disapprove-btn col-6">Disapprove</div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default EnquiryCard;
