import * as React from "react";
import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { DateTime } from "luxon";
import axios from "axios";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import { useModal, Modal } from "react-morphing-modal";
import "react-morphing-modal/dist/ReactMorphingModal.css";
import DateCalendarServerRequest from "../../components/Calendar";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
// import "./Appointment.css";
import "./Therapists.css";
import "./PatientDetail.css";
import { BASE_URL } from "../../apiConfig";

const CustomButton = ({ handleClick, id }) => {
  const btnRef = useRef(null);

  return (
    <button
      ref={btnRef}
      onClick={() => handleClick(id, btnRef)}
      className="bookAppointmentBtn"
    >
      Book Appointment
    </button>
  );
};

export default function Therapists(props) {
  const { modalProps, open, close } = useModal();

  const navigate = useNavigate();
  const [therapistData, setTherapistData] = useState([]);

  const [slotData, setSlotData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mainSlotData, setMainSlotData] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [therapistId, setTherapistId] = useState("");
  function gotodoctorprofilepage(id) {
    if (props.admin) {
      navigate(`/admin/therapist/therapist-detail/${id}`);
    } else {
      navigate(`/patient/therapist/therapist-detail/${id}`);
    }
  }
  useEffect(() => {
    fetch(`${BASE_URL}/therapist/getAllTherapist`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setTherapistData(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  }, []);

  function handleClick(id, btnRef) {
    open(btnRef);
    setTherapistId(id);
    setSlotData([]);

    fetch(`${BASE_URL}/therapist/getprofile/${id}`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setMainSlotData(data.mainSlot);
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  }

  const [slotId, setSlotId] = useState("");

  const handleSlotDataReceived = (data) => {
    console.log("settingggg");
    setSlotData(data);
    setIsLoading(false);
  };

  const handleSlotId = (id) => {
    setSlotId(id);
  };

  const handleBookSlot = () => {
    const bookSlot = () => {
      fetch(`${BASE_URL}/slot/book/${slotId}`, {
        method: "POST",
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Slot has been booked!");
          console.log(data.thirtyMinSlot);
          if (data.success) {
            toast.success(data.message || "Slot booked successfully!");
            close();
          } else {
            toast.error(data.message || "Error booking slot!");
          }
        })
        .catch((error) => {
          console.error("Error fetching slot data:", error);
          toast.error("Error booking slot! Please try again.");
        });
    };

    bookSlot();
  };

  const [patinet, setPatient] = React.useState();
  useEffect(() => {
    const fetchPatient = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/patient/details`, {
          withCredentials: true,
        });
        const patientObj = response.data;
        setPatient(patientObj);

        console.log(patientObj);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchPatient();
  }, []);
  const getStartEnd = (data) => {
    const { timezone, startTime, endTime, currentYear, date } = data;
    // Therapist timezone from the data
    const therapistTimeZone = data?.timezone || "Asia/Kolkata"; // Make sure this is defined
    const patientTimeZone = patinet.timeZone; // Ensure this is defined

    console.log({ therapistTimeZone, patientTimeZone });
    // Format the date for ISO
    const formattedDate = convertToYYYYMMDD(date, currentYear);
    console.log({ startTime, endTime }); // Log the formatted date
    console.log(props.data);
    // Create DateTime objects in the therapist's timezone
    const startDateTime = DateTime.fromISO(`${formattedDate}T${startTime}`, {
      zone: therapistTimeZone,
    });

    const endDateTime = DateTime.fromISO(`${formattedDate}T${endTime}`, {
      zone: therapistTimeZone,
    });

    // Convert the DateTime objects to the patient's timezone
    const startDateTimeInPatientTZ = startDateTime.setZone(patientTimeZone);
    const endDateTimeInPatientTZ = endDateTime.setZone(patientTimeZone);

    // Log the results
    console.log({
      startDateTimeInPatientTZ:
        startDateTimeInPatientTZ.toFormat("dd-MM-yyyy HH:mm"), // Example formatting
      endDateTimeInPatientTZ:
        endDateTimeInPatientTZ.toFormat("dd-MM-yyyy HH:mm"),
    });

    const start = startDateTimeInPatientTZ
      .toFormat("dd-MM-yyyy HH:mm")
      .split(" ");
    const end = endDateTimeInPatientTZ.toFormat("dd-MM-yyyy HH:mm").split(" ");
    return { start, end };
  };

  // Function to convert a date string to YYYY-MM-DD format
  function convertToYYYYMMDD(dateStr, currentYear) {
    const date = new Date(`${dateStr} ${currentYear}`); // Create a new date with the specified year
    const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with zero
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed) and pad with zero

    return `${currentYear}-${month}-${day}`; // Format as YYYY-MM-DD
  }

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#f5f5f7",
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 6, mb: 4 }}>
        <Grid container mb={2}>
          <Grid item xs={12}>
            {props.admin ? (
              <h5 style={{ fontWeight: "600" }}>All Therapists</h5>
            ) : (
              <>
                <h5 style={{ fontWeight: "600" }}>Book Appointment</h5>
                <p>
                  Book appointments with minimum wait-time & verified therapist
                </p>
              </>
            )}
          </Grid>
        </Grid>

        <Paper
          sx={{
            p: 1,
            display: "flex",
            flexDirection: "column",
            height: "auto",
          }}
        >
          {therapistData?.map((therapist, index) => (
            <div key={therapist._id}>
              <Grid
                // key={therapist._id}
                container
                spacing={1}
                sx={{
                  padding: "10px",
                }}
              >
                <Grid item xs={5} sm={5} md={3} lg={3} xl={2}>
                  {!therapist.profilePic ? (
                    <img
                      className="patient-detail-img"
                      src={require("../../images/person.jpg")}
                      alt="appimg"
                    />
                  ) : (
                    <img
                      className="patient-detail-img"
                      src={`${BASE_URL}/upload/${therapist.profilePic}`}
                      alt="appimg"
                    />
                  )}
                  {/* <img
                    className="patient-detail-img"
                    src={require("../../images/person.jpg")}
                    alt="personImg"
                  /> */}
                </Grid>

                <Grid
                  item
                  xs={7}
                  sm={6}
                  md={5}
                  lg={3}
                  xl={3}
                  sx={{
                    "margin-top": "20px",
                  }}
                >
                  <h6
                    onClick={() => gotodoctorprofilepage(therapist._id)}
                    style={{ cursor: "pointer" }}
                  >
                    <strong>{therapist.fullName}</strong>
                  </h6>
                  <p>{`${therapist.country} ${therapist.timezone}`}</p>
                  <p
                    style={{ margin: "0" }}
                  >{`${therapist.yearsOfExp}+ years of experience`}</p>
                  <Button
                    onClick={() => gotodoctorprofilepage(therapist._id)}
                    sx={{ backgroundColor: "#1e45ac", my: 2 }}
                    variant="contained"
                  >
                    View Profile
                  </Button>
                  {!props.admin && (
                    <CustomButton
                      id={therapist._id}
                      handleClick={handleClick}
                    />
                  )}
                </Grid>
                {!props.admin && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={6}
                    xl={6}
                    className="bookAppointmentBtnSection"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {/* <span>{activeModal}</span> */}
                    <Modal {...modalProps}>
                      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                        <ToastContainer zIndex={1000000} />

                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            className="appointmentContainer"
                            item
                            xs={12}
                            md={12}
                            lg={8}
                          >
                            <Paper
                              sx={{
                                p: 2,
                                display: "flex",
                                flexDirection: "column",
                                height: "auto",
                                overflowX: "scroll",
                              }}
                            >
                              <h5>Select Slot</h5>

                              <DateCalendarServerRequest
                                therapistId={therapistId}
                                data={mainSlotData}
                                onSlotDataReceived={handleSlotDataReceived}
                              />
                              <form>
                                <Grid
                                  container
                                  maxWidth="md"
                                  spacing={2}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    textAlign: "center",
                                    gap: "20px 15px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {slotData?.map((data, index) => (
                                    <>
                                      {data.status === false ? (
                                        <>
                                          <Grid
                                            item
                                            xs={9}
                                            sm={5}
                                            md={4}
                                            lg={5}
                                            className="time-button"
                                          >
                                            <input
                                              type="radio"
                                              id="a25"
                                              name="check-substitution-2"
                                              onChange={() =>
                                                handleSlotId(data._id)
                                              }
                                            />
                                            <label
                                              class="btn btn-default"
                                              for="a25"
                                            >
                                              {`${data.startTime}-${data.endTime}`}
                                            </label>
                                          </Grid>
                                        </>
                                      ) : (
                                        <>
                                          <Grid
                                            item
                                            xs={9}
                                            sm={5}
                                            md={4}
                                            lg={5}
                                            className="time-button"
                                          >
                                            <input
                                              type="radio"
                                              id="a25"
                                              name="check-substitution-2"
                                              onChange={() =>
                                                handleSlotId(data._id)
                                              }
                                            />
                                            <label
                                              style={{
                                                backgroundColor: "#E48586",
                                                cursor: "auto",
                                              }}
                                              class="btn btn-default"
                                              for="a25"
                                            >
                                              {`${data.startTime}-${data.endTime}`}
                                            </label>
                                          </Grid>
                                        </>
                                      )}
                                      {/* </Grid> */}
                                    </>
                                  ))}
                                </Grid>
                              </form>
                              <Grid
                                container
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginTop: "20px",
                                }}
                              >
                                <Grid item>
                                  <button
                                    style={{
                                      padding: "8px 30px",
                                      border: "none",
                                      borderRadius: "5px",
                                      backgroundColor: "#00bf63",
                                      color: "#fff",
                                    }}
                                    onClick={() =>
                                      handleBookSlot(therapist._id)
                                    }
                                  >
                                    Confirm
                                  </button>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Container>
                    </Modal>
                  </Grid>
                )}
              </Grid>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <hr style={{ width: "90%" }} />
              </div>
            </div>
          ))}
        </Paper>
      </Container>
    </Box>
  );
}
