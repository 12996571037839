import * as React from "react";
import { useState, useEffect } from "react";
import "./Patient.css";
import Box from "@mui/material/Box";
import timezones from "../../named-timezones-list.json";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import "../Patient/PatientProfile.css";
import AddIcon from "@mui/icons-material/Add";
// import toast from 'react-toastify'
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../apiConfig";

export default function TherepistProfile() {
  const [inputs, setInputs] = useState([""]);
  const [fullName, setFullName] = useState("");
  const [yearsOfExp, setYearsOfExp] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [overviewOfExp, setOverviewOfExp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [titles, setTitles] = useState([]);
  const [profilePic, setProfilePic] = useState("");
  const [timezone, setTimezone] = useState("");
  const [therapySpecialisation, setTherapySpecialisation] = useState("");

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange1 = (e) => {
    setSelectedFile1(e.target.files[0]);
  };

  const handleTitleChange = (index, value) => {
    const updatedTitles = [...titles];
    updatedTitles[index] = value;
    setTitles(updatedTitles);
  };

  const handleFileChange = (index, e) => {
    const updatedSelectedFiles = [...selectedFiles];
    updatedSelectedFiles[index] = e.target.files[0];
    setSelectedFiles(updatedSelectedFiles);
  };

  const addInput = () => {
    setInputs([...inputs, ""]);
  };

  const removeInput = () => {
    if (inputs.length > 1) {
      const updatedInputs = [...inputs];
      const updatedTitles = [...titles];
      const updatedCertificates = [...selectedFiles];
      updatedInputs.pop();
      updatedTitles.pop();
      updatedCertificates.pop();
      setInputs(updatedInputs);
      setTitles(updatedTitles);
      setSelectedFiles(updatedCertificates);
    }
  };

  useEffect(() => {
    fetch(`${BASE_URL}/therapist/details`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        data = data.therapist;
        setFullName(data.fullName);
        setYearsOfExp(data.yearsOfExp);
        setCountry(data.country);
        setEmail(data.email);
        setOverviewOfExp(data.overviewOfExp);
        setPhoneNumber(data.phoneNumber);
        setTherapySpecialisation(data.therapySpecialisation);
        setProfilePic(data.profilePic);
        setTimezone(data.timezone);
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  }, []);

  const submitProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("fullName", fullName);
      formData.append("yearsOfExp", yearsOfExp);
      formData.append("country", country);
      formData.append("email", email);
      formData.append("overviewOfExp", overviewOfExp);
      formData.append("phoneNumber", phoneNumber);
      formData.append("timezone", timezone);

      formData.append("therapySpecialisation", therapySpecialisation);
      if (selectedFile1) {
        formData.append("profilePic", selectedFile1);
      }

      titles.forEach((title, index) => {
        formData.append("titles[]", title);
        formData.append("certifications", selectedFiles[index]);
      });

      const response = await fetch(`${BASE_URL}/therapist/editprofile`, {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      if (response.ok) {
        toast.success("Profile Updated Successfully!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Failed to update profile. Please try again.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("An error occurred while updating profile.");
    }
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        padding: " 0 10px",
      }}
    >
      <Toolbar />
      <Container
        maxWidth="lg"
        sx={{
          mt: 4,
          mb: 4,
          backgroundColor: "#fff",
          borderRadius: "10px",
          padding: "15px 0 10px 0",
        }}
      >
        <div className="container rounded bg-white  mb-2">
          <div className="row">
            <div className="col-md-3 border-right">
              <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                {!profilePic ? (
                  <img
                    className="therapistImg"
                    width="150px"
                    src={require("../../images/person.jpg")}
                    alt="profile"
                  />
                ) : (
                  <img
                    className="therapistImg"
                    width="150px"
                    src={`${BASE_URL}/upload/${profilePic}`}
                    alt="profile"
                  />
                )}

                <span className="font-weight-bold">{fullName}</span>
                <span className="text-black-50">{email}</span>
                <span> </span>
              </div>
            </div>
            <div className="col-md-5 border-right">
              <div className="p-3 py-5">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h4 className="text-right">Profile Settings</h4>
                </div>
                <div className="row mt-2">
                  <div className="col-md-12">
                    <label className="labels">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g. John"
                      name="fullName"
                      value={fullName}
                      required
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <label className="labels">Years of Experience</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="06"
                      name="yearsOfExp"
                      value={yearsOfExp}
                      required
                      onChange={(e) => setYearsOfExp(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Country</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="London"
                      name="country"
                      required
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="labels">Timezone</label>
                    <select
                      class="form-select"
                      id="timezone"
                      aria-label="Floating label select example"
                      name="timezone"
                      value={timezone}
                      onChange={(e) => setTimezone(e.target.value)}
                    >
                      <option selected>Select Timezone*</option>
                      {timezones?.map((zone) => (
                        <option value={zone}>{zone}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Specialization in Therapy</label>
                    <input
                      type="text"
                      className="form-control"
                      name="therapySpecialisation"
                      required
                      value={therapySpecialisation}
                      onChange={(e) => setTherapySpecialisation(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Mobile Number</label>
                    <input
                      value={phoneNumber}
                      type="text"
                      className="form-control"
                      placeholder="9988XXXXXX"
                      name="phoneNumber"
                      required
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Email ID</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="john@gmail.com"
                      name="email"
                      value={email}
                      required
                      disabled
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Profile Picture</label>
                    <input
                      type="file"
                      className="form-control"
                      name="profilePic"
                      required
                      onChange={handleFileChange1}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Bio</label>
                    <input
                      type="text"
                      className="form-control bio"
                      name="overviewOfExp"
                      value={overviewOfExp}
                      required
                      onChange={(e) => setOverviewOfExp(e.target.value)}
                    />
                  </div>
                </div>
                {/* <div className="row mt-3">
                  <div className="col-md-6">
                    <label className="labels">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="********"
                      name="password"
                      value={password}
                      required
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="labels">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="********"
                      name="confirmPassword"
                      value={confirmPassword}
                      required
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div> */}
                {/* </div> */}
                {/* <div className="mt-5 text-center"><button className="btn btn-primary profile-button" type="button">Save Profile</button></div> */}
              </div>
            </div>
            <div className="col-md-4">
              <div className="p-3 py-5">
                <div
                  onClick={addInput}
                  className="d-flex justify-content-between align-items-center experience"
                >
                  <span>Certificate</span>
                  <span className="border px-3 p-1 add-experience">
                    <AddIcon /> Add Certificate
                  </span>
                </div>
                <br />
                {inputs?.map((inputValue, index) => (
                  <div key={index}>
                    <div className="col-md-12">
                      <label className="labels">Certificate</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="experience"
                        value={titles[index] || ""}
                        onChange={(e) =>
                          handleTitleChange(index, e.target.value)
                        }
                      />
                    </div>{" "}
                    <br />
                    <div className="col-md-12">
                      <label className="labels">Upload Certificate</label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => handleFileChange(index, e)}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-md-12 d-flex justify-content-center align-items-center">
                <button className="remove-btn" onClick={removeInput}>
                  Remove
                </button>
              </div>
            </div>
            <div className="mt-1 text-center">
              <button
                className="btn btn-primary profile-button"
                onClick={submitProfile}
                type="button"
              >
                Save Profile
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Container>
    </Box>
  );
}
