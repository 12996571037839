import * as React from "react";
import { useState, useEffect } from "react";
import { BASE_URL } from "../../apiConfig";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppointmentCard from "../../components/therapist/AppointmentCard";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Chart from "../../components/patient/Chart";

function BasicDateCalendar() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar />
    </LocalizationProvider>
  );
}

export default function PatientDashboard() {
  const [slotPastData, setSlotPastData] = useState([]);
  const [slotUpcomingData, setSlotUpcomingData] = useState([]);

  useEffect(() => {
    fetch(`${BASE_URL}/slot/bookedSlots/patient`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('--------->',data);
        setSlotPastData(data.pastSlots);
        setSlotUpcomingData(data.upcomingSlots);
      })
      .catch((error) => {
        console.error("Error fetching slot data:", error);
      });
  }, []);

  const data1 = [10, 12, 8, 10, 15, 9, 7, 8, 9, 10];
  const labels = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"];
  const data = {
    labels: labels,
    datasets: [
      {
        label: "No. of Appointment",
        data: data1,
        borderColor: "#00bf63",
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          text: "Weeks",
        },
      },
      y: {
        title: {
          display: true,
          text: "No. of Appointment",
        },
      },
    },
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#fff",
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        overflowX: "hidden",
      }}
    >
      <Toolbar />

      <Container maxWidth="xxl" sx={{ mt: 4 }}>
        <Grid
          container
          spacing={1}
          mt={2}
          mb={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item sm={12} md={11} lg={8} xl={7} xxl={6}>
            <h5>Upcoming appointments</h5>

            {slotUpcomingData?.length <= 0 && (
              <p style={{ marginBottm: 0 }}>No upcoming appointments!</p>
            )}

            {slotUpcomingData?.map((slot) => (
              <Grid
                key={slot._id}
                item
                sx={{
                  mt: 2,
                }}
              >
                <AppointmentCard
                  status="upcoming"
                  type="therapist"
                  data={slot}
                />
              </Grid>
            ))}

            <Grid item sx={{ mt: 3 }}>
              <h5 style={{ marginBottom: "20px" }}>Past appointments</h5>
            </Grid>

            {slotPastData?.length <= 0 && (
              <p style={{ marginBottm: 0 }}>No appointments yet!</p>
            )}

            {slotPastData?.map((slot) => (
              <Grid key={slot._id} item sx={{ mt: 2 }}>
                <AppointmentCard status="past" type="therapist" data={slot} />
              </Grid>
            ))}
          </Grid>
          <Grid
            item
            sm={12}
            md={12}
            lg={4}
            xl={5}
            xxl={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "15px 0",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <div style={{ border: "3px solid #f5f5f7" }}>
                <BasicDateCalendar />
              </div>
            </div>
            <Chart data={data} options={options} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
