import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Why from "../components/Why";
import Who from "../components/Who";
import Program from "../components/Program";
import Mission from "../components/Mission";
import Therapist from "../components/Therapist";
import Footer from "../components/Footer";
import AnimatedCursor from "react-animated-cursor";

function LandingPage() {
  return (
    <div className="App">
      <AnimatedCursor
        innerSize={10}
        outerSize={38}
        showSystemCursor={true}
        color="0, 191, 99"
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={2}
        clickables={[
          "a",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "textarea",
          "button",
          ".link",
          "link",
        ]}
      />
      {/* <div> */}
      <Navbar />
      <Hero />
      <Who />
      <Why />
      <Program />
      <Mission />
      <Therapist />
      <Footer />
      {/* </div> */}
    </div>
  );
}

export default LandingPage;
