import * as React from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useModal, Modal } from "react-morphing-modal";
import DateCalendarServerRequest from "../../components/Calendar";
import { DateTime } from "luxon";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { BASE_URL } from "../../apiConfig";
import "./TherapistDetail.css";
import { FaStar } from "react-icons/fa";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Rating from "@mui/material/Rating";
import { Typography } from "@mui/material";
import { useNavigate, useParams, Link } from "react-router-dom";
// import toast
import { ToastContainer, toast } from "react-toastify";

const colors = {
  orange: "#FFBA5A",
  grey: "#a9a9a9",
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // position:"absolute",
    // backgroundColor:"#f5f5f7",
    // left:"50%",
    // top:"50%"
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid grey",
    borderRadius: "6px",
    padding: "25px 15px",
  },
  stars: {
    display: "flex",
    flexDirection: "row",
  },
  textarea: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    padding: 10,
    margin: "20px 0",
    minHeight: 100,
    width: 300,
    resize: "none",
    outline: "none",
  },
  button: {
    border: "none",
    backgroundColor: "#00BF63",
    color: "#fff",
    borderRadius: 5,
    width: 300,
    padding: 10,
  },
};

const Button = ({ handleClick, id }) => {
  // const [activeId, setActiveId] = useState(null);
  const btnRef = useRef(null);
  // console.log("btn Ref" + btnRef);
  // console.log("button" + id);
  return (
    <button
      ref={btnRef}
      onClick={() => handleClick(id, btnRef)}
      className="bookAppointmentBtn"
    >
      Book Appointment
    </button>
  );
};

export default function Therapistdetail(props) {
  const { modalProps, open } = useModal();

  const navigate = useNavigate();
  const params = useParams();
  const [therapist, setTherapist] = useState();
  const [mainSlotData, setMainSlotData] = useState([]);
  const [slotId, setSlotId] = useState("");
  const [slotData, setSlotData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [currentValue, setCurrentValue] = useState(0);
  const [comment, setComment] = useState("");
  const [reviews, setReviews] = useState([]);
  const [hoverValue, setHoverValue] = useState(undefined);
  const [feedbackForm, setFeedbackForm] = useState(false);
  const stars = Array(5).fill(0);

  const [patient, setPatient] = useState({});
  useEffect(() => {
    const fetchPatient = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/patient/details`, {
          withCredentials: true,
        });
        const patientObj = response.data;
        setPatient({
          studentName: patientObj.studentName,
          parentName: patientObj.parentName,
          age: patientObj.age,
          primaryLanguage: patientObj.primaryLanguage,
          country: patientObj.country,
          timeZone: patientObj.timeZone,
          email: patientObj.email,
          expectations: patientObj.expectations,
          profilePic: patientObj.profilePic,
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchPatient();
  }, []);

  useEffect(() => {
    async function getTherapistData() {
      try {
        const response = await axios.get(
          `${BASE_URL}/therapist/details/${params.therapistId}`
        );
        const therapist = response.data.therapist;
        setTherapist(therapist);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching therapist:", error);
        setIsLoading(false);
      }
    }

    getTherapistData();

    async function getTherapistReviews() {
      try {
        const response = await axios.get(
          `${BASE_URL}/therapist/${params.therapistId}/reviews`
        );
        const fetchedReviews = response.data.reviews;
        console.log(fetchedReviews);
        setReviews(fetchedReviews);
      } catch (error) {
        console.log("Error fetching reviews:", error);
      }
    }

    getTherapistReviews();
  }, [params.therapistId]);

  async function disapproveTherapist() {
    try {
      const response = await axios.post(
        `${BASE_URL}/admin/disapprove-therapist`,
        {
          therapistId: params.therapistId,
        },
        { withCredentials: true }
      );

      console.log(response.data);

      if (response.data.success) {
        toast.success("Therapist has been suspended!");
      } else {
        toast.error("Therapist not found!");
      }
    } catch (error) {
      toast.error("An error occurred while suspending therapist");
    }
  }

  function handleClick(id, btnRef) {
    open(btnRef);
    setSlotData([]);

    fetch(`${BASE_URL}/therapist/getprofile/${id}`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setMainSlotData(data.mainSlot);
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  }

  const handleSlotDataReceived = (data) => {
    setSlotData(data);
    setIsLoading(false);
  };

  const handleSlotId = (id) => {
    setSlotId(id);
    console.log(id);
  };

  console.log({ slotData });
  const handleBookSlot = () => {
    const bookSlot = () => {
      fetch(`${BASE_URL}/slot/book/${slotId}`, {
        method: "POST",
        credentials: "include",
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Slot has been booked!");
          console.log(data.thirtyMinSlot);
          if (data.success) {
            toast.success(data.message || "Slot booked successfully!");
          } else {
            toast.error(data.message || "Error booking slot!");
          }
        })
        .catch((error) => {
          console.error("Error fetching slot data:", error);
          toast.error("Error booking slot! Please try again.");
        });
    };

    bookSlot();
  };

  const handleStars = (value) => {
    setCurrentValue(value);
    console.log("Value ", value);
  };

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  async function postReviewHandler(e) {
    e.preventDefault();
    console.log(currentValue);
    console.log(comment);
    try {
      const response = await axios.post(
        `${BASE_URL}/patient/${params.therapistId}/postReview`,
        {
          rating: currentValue,
          comment,
        },
        { withCredentials: true }
      );

      console.log("Review saved successfully:", response.data.review);
      toast.success("Feedback sent!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setCurrentValue(0);
      setComment("");
    } catch (error) {
      toast.error("Error posting feedback!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log("Error saving review:", error.response.data);
    }
    toggleFeebackForm();
  }

  function toggleFeebackForm() {
    setFeedbackForm(!feedbackForm);
  }

  const formatReviewDate = (createdAt) => {
    const now = moment();
    const reviewDate = moment(createdAt);

    if (now.isSame(reviewDate, "day")) {
      return reviewDate.fromNow();
    } else {
      return reviewDate.format("DD MMM YYYY");
    }
  };

  // const { modalProps, getTriggerProps } = useModal({
  //   background: "#21252994;",
  // });
  // const data = [{name: 'Page A', uv: 400, pv: 2400, amt: 2400}];
  function goToBack() {
    props.admin
      ? navigate("/admin/therapists")
      : navigate("/patient/therapists");
  }
  // window.location.href = "admin/patient/therapists";

  if (isLoading) {
    return <p>Loading...</p>;
  }

  function convertToYYYYMMDD(dateStr, currentYear) {
    const date = new Date(`${dateStr} ${currentYear}`); // Create a new date with the specified year
    const day = String(date.getDate()).padStart(2, "0"); // Get day and pad with zero
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-indexed) and pad with zero

    return `${currentYear}-${month}-${day}`; // Format as YYYY-MM-DD
  }
  const getFormattedTime = (data) => {
    const { currentYear, date, startTime, endTime } = data;
    const therapistTimeZone = data?.timezone || "Asia/Kolkata";
    const patientTimeZone = patient?.timeZone || "Asia/Kolkata";
    console.log({ therapistTimeZone, patientTimeZone });
    const formattedDate = convertToYYYYMMDD(date, currentYear);
    const startDateTime = DateTime.fromISO(`${formattedDate}T${startTime}`, {
      zone: therapistTimeZone,
    });
    const endDateTime = DateTime.fromISO(`${formattedDate}T${endTime}`, {
      zone: therapistTimeZone,
    });
    const startDateTimeInPatientTZ = startDateTime.setZone(patientTimeZone);
    const endDateTimeInPatientTZ = endDateTime.setZone(patientTimeZone);
    const start = startDateTimeInPatientTZ
      .toFormat("dd-MM-yyyy HH:mm")
      .split(" ");
    const end = endDateTimeInPatientTZ.toFormat("dd-MM-yyyy HH:mm").split(" ");
    return { start, end };
  };
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        padding: " 0 10px",
      }}
    >
      <Toolbar />
      <Container
        sx={{
          mt: 4,
          mb: 4,
          paddingLeft: "0px",
        }}
      >
        <Grid
          item
          sx={{
            paddingLeft: "0px",
          }}
        >
          <p
            style={{ fontWeight: "bold", cursor: "pointer" }}
            onClick={goToBack}
          >
            <KeyboardBackspaceOutlinedIcon /> Back to list
          </p>
        </Grid>
      </Container>
      <Container
        maxWidth="lg"
        sx={{
          mt: 4,
          mb: 4,
          backgroundColor: "#fff",
          borderRadius: "10px",
          padding: "15px 0 10px 0",
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Grid item xs={5} sm={5} md={3} lg={3} xl={3}>
            {!therapist.profilePic ? (
              <img
                className="patient-detail-img"
                src={require("../../images/person.jpg")}
                alt="appimg"
              />
            ) : (
              <img
                className="patient-detail-img"
                src={`${BASE_URL}/upload/${therapist.profilePic}`}
                alt="appimg"
              />
            )}
            {/* <img
              className="patient-detail-img"
              src={require("../../images/person.jpg")}
              alt="personImg"
            /> */}
          </Grid>
          <Grid item xs={7} sm={6} md={5} lg={3} xl={3}>
            <h6>
              <strong>{therapist.fullName}</strong>
            </h6>
            <p>{`${therapist.country} (${therapist.timezone})`}</p>
            <p style={{ margin: "0" }}>
              {therapist.yearsOfExp} years of Experince
            </p>
          </Grid>
          {!props.admin && (
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={6}
              xl={6}
              className="bookAppointmentBtnSection"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p style={{ marginBottom: "5px" }}>Check Availability</p>
              <Button id={therapist._id} handleClick={handleClick} />

              {/* <button {...getTriggerProps()} className="bookAppointmentBtn">
                Book an appointment
              </button> */}

              <Modal {...modalProps}>
                <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                  <ToastContainer zIndex={1000000} />

                  <Grid
                    container
                    spacing={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      className="appointmentContainer"
                      item
                      xs={12}
                      md={12}
                      lg={8}
                    >
                      <Paper
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          height: "auto",
                          overflowX: "scroll",
                        }}
                      >
                        <h5>Select Slot</h5>
                        {/* <h6>
                          Click on a highlighted date to view available slots.
                        </h6> */}

                        <DateCalendarServerRequest
                          therapistId={params.therapistId}
                          data={mainSlotData}
                          onSlotDataReceived={handleSlotDataReceived}
                        />
                        <form>
                          <Grid
                            container
                            maxWidth="md"
                            spacing={2}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              textAlign: "center",
                              gap: "20px 15px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {slotData?.map((data, index) => (
                              <>
                                {isLoading && <p>Loading slots...</p>}
                                {!isLoading && data.status === false ? (
                                  <>
                                    <Grid
                                      item
                                      xs={9}
                                      sm={5}
                                      md={4}
                                      lg={5}
                                      className="time-button"
                                    >
                                      <input
                                        type="radio"
                                        id="a25"
                                        name="check-substitution-2"
                                        onChange={() => handleSlotId(data._id)}
                                      />
                                      <label class="btn btn-default" for="a25">
                                        {`${data.startTime}-${data.endTime}`}
                                      </label>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      xs={9}
                                      sm={5}
                                      md={4}
                                      lg={5}
                                      className="time-button"
                                    >
                                      <input
                                        type="radio"
                                        id="a25"
                                        name="check-substitution-2"
                                        onChange={() => handleSlotId(data._id)}
                                      />
                                      <label
                                        style={{
                                          backgroundColor: "#E48586",
                                          cursor: "auto",
                                        }}
                                        class="btn btn-default"
                                        for="a25"
                                      >
                                        {`${data.startTime}-${data.endTime}`}
                                      </label>
                                    </Grid>
                                  </>
                                )}
                                {/* </Grid> */}
                              </>
                            ))}
                          </Grid>
                        </form>
                        <Grid
                          container
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "20px",
                          }}
                        >
                          <Grid item>
                            <button
                              style={{
                                padding: "8px 30px",
                                border: "none",
                                borderRadius: "5px",
                                backgroundColor: "#00bf63",
                                color: "#fff",
                              }}
                              onClick={() => handleBookSlot(therapist._id)}
                            >
                              Confirm
                            </button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Container>
              </Modal>
            </Grid>
          )}
        </Grid>

        <Grid
          sx={{
            mt: 3,
            padding: "15px",
          }}
        >
          <h5 style={{ fontWeight: "bold" }}>Bio</h5>
          <p>{therapist.overviewOfExp}</p>
          <Grid xs={12}>
            <p style={{ fontWeight: "bold" }}>Certifications : </p>
            <Grid
              container
              sm={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "15px 15px",
              }}
            >
              {therapist?.certificates?.map((certificate) => (
                <Grid
                  item
                  xs={12}
                  sm={8}
                  lg={5}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0 15px",
                  }}
                >
                  <p style={{ marginBottom: "0px" }}>{certificate.title}</p>
                  <Link
                    to={`${BASE_URL}/upload/${certificate.fileName}`}
                    target="_blank"
                  >
                    <button
                      className="viewCertificate-btn"
                      onClick={() => console.log(certificate.fileName)}
                    >
                      View file
                    </button>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            container
            mt={3}
            xs={12}
            md={8}
            lg={3}
            style={{ display: "flex", gap: "0 15px" }}
          >
            <p style={{ fontWeight: "bold" }}>Email : </p>
            <p>{therapist.email}</p>
          </Grid>
        </Grid>
        {!props.admin && (
          <Grid
            sx={{
              mt: 1,
              padding: "0 15px",
            }}
          >
            <button
              onClick={toggleFeebackForm}
              style={{
                backgroundColor: "#1e45ac",
                color: "#fff",
                borderRadius: "5px",
                padding: "8px 25px",
                border: "none",
              }}
            >
              Give Feedback
            </button>
            {feedbackForm && (
              <form onSubmit={postReviewHandler}>
                <div style={styles.container}>
                  <div style={styles.div}>
                    <h4> Feedback </h4>
                    <div style={styles.stars}>
                      {stars.map((_, index) => {
                        return (
                          <FaStar
                            name="rating"
                            value={currentValue}
                            key={index}
                            size={24}
                            onClick={() => handleStars(index + 1)}
                            onMouseOver={() => handleMouseOver(index + 1)}
                            onMouseLeave={handleMouseLeave}
                            color={
                              (hoverValue || currentValue) > index
                                ? colors.orange
                                : colors.grey
                            }
                            style={{
                              marginRight: 10,
                              cursor: "pointer",
                            }}
                          />
                        );
                      })}
                    </div>
                    <textarea
                      name="comment"
                      placeholder="What's your experience?"
                      style={styles.textarea}
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />

                    <button type="submit" style={styles.button}>
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Grid>
        )}
        <Grid
          sx={{
            mt: 4,
            padding: "0 15px",
          }}
        >
          <h5 style={{ fontWeight: "bold" }}>Reviews</h5>

          {reviews.length <= 0 && <p>No reviews yet!</p>}

          {reviews?.map((review) => (
            <>
              <Stack direction="row" spacing={3} mt={3}>
                <Avatar
                  alt="Remy Sharp"
                  src={`${BASE_URL}/upload/${review.profilePic}`}
                  sx={{ width: 56, height: 56 }}
                />
                <Grid>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {review.patientName}
                  </Typography>
                  <Typography>{review.country}</Typography>
                  <p style={{ fontSize: "0.8rem", color: "grey" }}>
                    posted {formatReviewDate(review.createdAt)}
                  </p>
                </Grid>
                <Grid>
                  <Rating
                    name="rating"
                    size="small"
                    value={review.rating}
                    readOnly
                  />
                </Grid>
              </Stack>

              <Grid mt={2}>
                <p style={{ marginBottom: "5px" }}>{review.comment}</p>
              </Grid>
              <hr />
            </>
          ))}
        </Grid>

        {props.admin && (
          <Grid
            container
            mt={4}
            mb={4}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Grid item>
              <button
                style={{
                  padding: "8px 30px",
                  border: "none",
                  borderRadius: "4px",
                  backgroundColor: "#a90909",
                  color: "#fff",
                }}
                onClick={disapproveTherapist}
              >
                Suspend Therapist
              </button>
            </Grid>
          </Grid>
        )}

        {/* <Copyright sx={{ pt: 4 }} /> */}
      </Container>
    </Box>
  );
}
