import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import SignIn from "./components/Auth/Login/Login";
import SignUp from "./components/Auth/Signup/signup";
// import Forgot from "./components/Auth/Forgot/Forgot";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Template from "./components/Template";
import TherapistDashboard from "./pages/Therapist/Dashboard";
import PatientDashboard from "./pages/Patient/Dashboard";
import TherapistAppointment from "./pages/Therapist/Appointment";
import TherapistCalendar from "./pages/Therapist/Calendar";
import Therapistdetail from "./pages/Therapist/TherapistDetail";
import Patientdetail from "./pages/Patient/PatientDetail";
import Patients from "./pages/Therapist/Patients";
import Therapists from "./pages/Patient/Therapists";
import PatientCalendar from "./pages/Patient/PatientCalendar";
import Enquiry from "./pages/Therapist/Enquiry";
import EnquiryAdmin from "./pages/Therapist/EnquiryAdmin";
import AdminLogin from "./pages/Admin/AdminLogin";
import AdminDashboard from "./pages/Admin/Dashboard";
import Progress from "./pages/Patient/Progress";
import TherapistProfile from "./pages/Therapist/Profile";
import PatientProfile from "./pages/Patient/PatientProfile";
import AdminProfile from "./pages/Admin/Profile";
// import PatientAuth from "./pages/Patient/PatientAuth";
// import TherapistAuth from "./pages/Therapist/TherapistAuth";
import TherapistSignUp from "./pages/Therapist/TherapistSignUp";
// import Homepage from "./pages/Homepage";
import LandingPage from "./_mainWebsite/Page/LandingPage";
import TherapistSignin from "./_mainWebsite/Page/TherapistSignin";

import PublicRoutesAdmin from "./utils/PublicRoutesAdmin";
import PublicRoutesTherapist from "./utils/PublicRoutesTherapist";
import PublicRoutesPatient from "./utils/PublicRoutesPatient";
import PrivateRoutesAdmin from "./utils/PrivateRoutesAdmin";
import PrivateRoutesTherapist from "./utils/PrivateRoutesTherapist";
import PrivateRoutesPatient from "./utils/PrivateRoutesPatient";
import TermsConditions from "./_mainWebsite/Page/TermsConditions";
import PrivacyPolicy from "./_mainWebsite/Page/PrivacyPolicy";
import RefundCancellationPolicy from "./_mainWebsite/Page/RefundCancellationPolicy";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/refund-cancellation-policy"
            element={<RefundCancellationPolicy />}
          />

          <Route path="/" element={<PublicRoutesTherapist />}>
            <Route
              path="/therapist/auth"
              element={<TherapistSignin page="therapist" />}
            />
            <Route path="/therapist/signup" element={<TherapistSignUp />} />
          </Route>

          <Route path="/" element={<PublicRoutesPatient />}>
            <Route
              path="/patient/auth"
              element={<TherapistSignin page="patient" />}
            />
            <Route path="/patient/signup" element={<SignUp />} />
          </Route>

          <Route path="/" element={<PublicRoutesAdmin />}>
            <Route path="/admin/login" element={<AdminLogin />} />
          </Route>

          <Route path="/therapist" element={<PrivateRoutesTherapist />}>
            <Route
              path="/therapist"
              element={
                <Template pov="therapist" exact page={<TherapistDashboard />} />
              }
            />
            <Route
              path="/therapist/patients"
              element={<Template pov="therapist" page={<Patients />} />}
            />
            <Route
              path="/therapist/patient/patient-detail/:patientId"
              element={<Template pov="therapist" page={<Patientdetail />} />}
            />
            <Route
              path="/therapist/calendar"
              element={
                <Template pov="therapist" page={<TherapistCalendar />} />
              }
            />
            <Route
              path="/therapist/appointment"
              element={
                <Template pov="therapist" page={<TherapistAppointment />} />
              }
            />
            <Route
              path="/therapist/enquiry"
              element={
                <Template pov="therapist" page={<Enquiry pov="therapist" />} />
              }
            />
            <Route
              path="/therapist/profile"
              element={<Template pov="therapist" page={<TherapistProfile />} />}
            />
          </Route>

          <Route path="/" element={<PrivateRoutesPatient />}>
            <Route
              path="/patient"
              element={<Template pov="patient" page={<PatientDashboard />} />}
            />
            <Route
              path="/patient/therapist/therapist-detail/:therapistId"
              element={<Template pov="patient" page={<Therapistdetail />} />}
            />
            <Route
              path="/patient/therapists"
              element={<Template pov="patient" page={<Therapists />} />}
            />
            <Route
              path="/patient/calendar"
              element={<Template pov="patient" page={<PatientCalendar />} />}
            />
            <Route
              path="/patient/progress"
              element={<Template pov="patient" page={<Progress />} />}
            />
            <Route
              path="/patient/profile"
              element={<Template pov="patient" page={<PatientProfile />} />}
            />
          </Route>

          <Route path="/" element={<PrivateRoutesAdmin />}>
            <Route
              path="/admin"
              element={<Template pov="admin" page={<AdminDashboard />} />}
            />
            <Route
              path="/admin/therapists"
              element={
                <Template pov="admin" page={<Therapists admin="true" />} />
              }
            />
            <Route
              path="/admin/therapist/therapist-detail/:therapistId"
              element={
                <Template pov="admin" page={<Therapistdetail admin="true" />} />
              }
            />
            <Route
              path="/admin/patients"
              element={
                <Template pov="admin" page={<Patients admin="true" />} />
              }
            />
            <Route
              path="/admin/patient/patient-detail/:patientId"
              element={
                <Template pov="admin" page={<Patientdetail admin="true" />} />
              }
            />
            <Route
              path="/admin/enquiry"
              element={
                <Template pov="admin" page={<EnquiryAdmin pov="admin" />} />
              }
            />
            <Route
              path="/admin/profile"
              element={<Template pov="admin" page={<AdminProfile />} />}
            />
          </Route>
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
