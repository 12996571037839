import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../apiConfig";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import Paper from "@mui/material/Paper";
import "./PatientDetail.css";
import ActivityPage from "../../components/ActivityPage";
import Chart from "../../components/patient/Chart";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useCallback } from "react";

export default function Patientdetail(props) {
  const params = useParams();
  const location = useLocation();
  const [actualGraph, setActualGraph] = useState([]);
  const [expectationGraph, setExpectationGraph] = useState([]);
  const [isTherapist, setIsTherapist] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);
  const { isEdit } = location.state || {};

  const [patient, setPatient] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const labels = expectationGraph;
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Expected Progress",
        data: expectationGraph,
        borderColor: "#00bf63",
      },
      {
        label: "Actual Progress",
        data: actualGraph,
        fill: false,
        borderColor: "#2242a3",
      },
    ],
  };
  const options = {
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          text: "Days",
        },
      },
      y: {
        title: {
          display: true,
          text: "Trials",
        },
      },
    },
    maintainAspectRatio: true,
  };

  async function tableChangeHandler(
    yearIndex,
    monthIndex,
    weekIndex,
    yearDataArray
  ) {
    try {
      const response = await axios.post(
        `${BASE_URL}/therapist/update/tabledata/${params.patientId}`,
        {
          progressData: yearDataArray,
        },
        { withCredentials: true }
      );

      if (response.status === 200) {
        toast.success("Table Updated!");
      } else {
        toast.error("An error occurred!");
      }
    } catch (error) {
      toast.error("An error occurred!");
    }
  }

  useEffect(() => {
    const currentUrl = location.pathname;
    if (currentUrl.startsWith("/therapist")) {
      setIsTherapist(true);
    } else if (currentUrl.startsWith("/admin")) {
      setIsAdmin(true);
    }
  }, [location]);

  useEffect(() => {
    async function getPatientData() {
      try {
        const response = await axios.get(
          `${BASE_URL}/patient/detail/${params.patientId}`
        );
        const patient = response.data;
        setPatient(patient);
        setIsLoading(false);
        createGraphArrays(patient);
      } catch (error) {
        setIsLoading(false);
      }
    }

    getPatientData();
  }, []);

  const navigate = useNavigate();

  function goToBack() {
    props.admin ? navigate("/admin/patients") : navigate("/therapist/patients");
  }

  const createGraphArrays = useCallback((patient) => {
    const trialsData = [];
    let trialsValueArray = [];
    const progressGraphArray = [];
    const expectationGraphArray = [];

    patient.progressData.forEach((yearData) => {
      const { year, months } = yearData;

      months.forEach((monthData) => {
        const { monthNumber, weeks } = monthData;

        weeks.forEach((weekData) => {
          const { weekNumber, activities } = weekData;

          for (let day = 1; day <= 7; day++) {
            activities.forEach((activityData) => {
              const { activityNumber, days } = activityData;

              const dayData = days.find((dayData) => dayData.day === day);

              if (dayData) {
                const { trials } = dayData;

                const trialValue1 = trials[0];

                trialsData.push({
                  activity: activityNumber,
                  day: day,
                  trial: "1",
                  trialValue: trialValue1,
                });
              }
            });

            activities.forEach((activityData) => {
              const { activityNumber, days } = activityData;

              const dayData = days.find((dayData) => dayData.day === day);

              if (dayData) {
                const { trials } = dayData;

                const trialValue2 = trials[1];

                trialsData.push({
                  activity: activityNumber,
                  day: day,
                  trial: "2",
                  trialValue: trialValue2,
                });
              }
            });
          }
        });
      });
    });
    trialsValueArray = trialsData?.map((data) => data.trialValue);

    let progress = 0;
    let expectation = 0;
    let stopAddingValues = false;
    trialsValueArray.forEach((value) => {
      if (!stopAddingValues) {
        expectation++;
        expectationGraphArray.push(expectation);
        if (value === "success") {
          progress++;
          progressGraphArray.push(progress);
        } else {
          progressGraphArray.push(progress);
        }

        if (value === "none") {
          stopAddingValues = true;
        }
      }
    });
    setActualGraph(progressGraphArray);
    setExpectationGraph(expectationGraphArray);
  }, []);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        overflowX: "hidden",
        padding: " 0 10px",
      }}
    >
      <ToastContainer />
      <Toolbar />
      <Container
        sx={{
          mt: 4,
          mb: 4,
          paddingLeft: "0px",
        }}
      >
        <Grid
          item
          sx={{
            paddingLeft: "0px",
          }}
        >
          <p
            style={{ fontWeight: "bold", cursor: "pointer" }}
            onClick={goToBack}
          >
            <KeyboardBackspaceOutlinedIcon /> Back to list
          </p>
        </Grid>
      </Container>
      <Container
        maxWidth="lg"
        sx={{
          mt: 4,
          mb: 4,
          backgroundColor: "#fff",
          borderRadius: "10px",
          padding: "15px ",
        }}
      >
        <Grid
          container
          spacing={3}
          sx={{
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Grid item xs={5} sm={4} md={3} lg={2}>
            {!patient.profilePic ? (
              <img
                className="patient-detail-img"
                src={require("../../images/person.jpg")}
                alt="ptientImg"
              />
            ) : (
              <img
                className="patient-detail-img"
                src={`${BASE_URL}/upload/${patient.profilePic}`}
                alt="ptientImg"
              />
            )}
          </Grid>
          <Grid item xs={7} sm={4} md={3} lg={3}>
            <h6>
              <strong>{patient.studentName}</strong>
            </h6>
            <p>{`${patient.country} (${patient.timeZone})`}</p>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={{ sm: 4, md: 4 }}
          maxWidth="lg"
          sx={{
            mt: 4,
            justifyContent: "space-around",
            padding: "10px",
          }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "0 40px",
            }}
            xs={12}
            sm={6}
            md={5}
          >
            <h5>Name of the Parent</h5>
            <p>{patient.parentName}</p>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "0 40px",
            }}
            xs={12}
            sm={6}
            md={5}
          >
            <h5>Name of the Student</h5>
            <p>{patient.studentName}</p>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "0 40px",
            }}
            xs={12}
            sm={6}
            md={5}
          >
            <h5>Age of the student</h5>
            <p>{patient.age}</p>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "0 40px",
            }}
            xs={12}
            sm={6}
            md={5}
          >
            <h5>Primary Language Spoken</h5>
            <p>{patient.primaryLanguage}</p>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "0 40px",
            }}
            xs={12}
            sm={6}
            md={5}
          >
            <h5>Country</h5>
            <p>{patient.country}</p>
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "0 40px",
            }}
            xs={12}
            sm={6}
            md={5}
          >
            <h5>Timezone</h5>
            <p>{patient.timeZone}</p>
          </Grid>
        </Grid>
        <Grid
          sx={{
            mt: 2,
          }}
        >
          <h5>Expectation from Therapy</h5>
          <p>{patient.expectations}</p>
        </Grid>
        <h5>Activity</h5>
        <Grid container>
          <ActivityPage
            patientData={patient.progressData}
            edit={isEdit}
            therapistView={isTherapist}
            adminView={isAdmin}
            onTableUpdate={tableChangeHandler}
          />
        </Grid>
        <Grid
          item
          sx={{
            mt: 4,
          }}
        >
          <h5>Activity Chart</h5>
        </Grid>
        <Grid
          container
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Chart data={data} options={options} />
          {/* </Grid> */}
          {/* <Grid item xs={12} md={5}>
            <Grid item xs={12} lg={9}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 100,
                  background: "#eeecf9",
                }}
              >
                <h5 style={{ textAlign: "center" }}>Total Session</h5>
                <h2 style={{ textAlign: "center" }}>25</h2>
              </Paper>
            </Grid>
            <Grid sx={{ marginTop: "10px" }} item xs={12} lg={9}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 100,
                  background: "#fdf6e9",
                }}
              >
                <h5 style={{ textAlign: "center" }}>Upcoming Session</h5>
                <h2 style={{ textAlign: "center" }}>5</h2>
              </Paper>
            </Grid>
          </Grid> */}
        </Grid>

        {/* {props.admin && (
          <Grid
            container
            mt={4}
            mb={4}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Grid item>
              <button
                style={{
                  padding: "8px 30px",
                  border: "none",
                  borderRadius: "4px",
                  backgroundColor: "#a90909",
                  color: "#fff",
                }}
              >
                Suspend Patient
              </button>
            </Grid>
          </Grid>
        )} */}
      </Container>
    </Box>
  );
}
