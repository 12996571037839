import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import timezones from "../../../named-timezones-list.json";
import countries from "../../../countries.json";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { BASE_URL } from "../../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../../../_mainWebsite/components/Footer";
import BasicNavbar from "../../../_mainWebsite/components/BasicNavbar";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignUp() {
  const location = useLocation();
  const navigate = useNavigate();
  const [studentName, setStudentName] = useState("");
  const [parentName, setParentName] = useState("");
  const [age, setAge] = useState("");
  const [primaryLanguage, setPrimaryLanguage] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [country, setCountry] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [expectations, setExpectations] = useState("");

  const { email } = location.state || {};

  function handleStudentNameChange(e) {
    setStudentName(e.target.value);
  }

  function handleParentNameChange(e) {
    setParentName(e.target.value);
  }
  function handleAgeChange(e) {
    setAge(e.target.value);
  }
  function handleLanguageChange(e) {
    setPrimaryLanguage(e.target.value);
  }
  function handleCountryChange(e) {
    setCountry(e.target.value);
  }
  function handleTimeZoneChange(e) {
    setTimeZone(e.target.value);
  }
  function handleExpectationsChange(e) {
    setExpectations(e.target.value);
  }

  function handleProfilePicChange(e) {
    setProfilePic(e.target.files[0]);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("studentName", studentName);
    formData.append("parentName", parentName);
    formData.append("age", age);
    formData.append("primaryLanguage", primaryLanguage);
    formData.append("country", country);
    formData.append("timeZone", timeZone);
    formData.append("email", email);
    formData.append("expectations", expectations);

    if (profilePic) {
      formData.append("profilePic", profilePic);
    }

    try {
      await axios.post(`${BASE_URL}/patientSignup`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });

      console.log("Successfully registered!");
      toast.success("Successfully signed up!");
      navigate("/patient");
    } catch (error) {
      toast.error("Error during sign up!");
      console.error("Error during sign up");
    }
  }

  return (
    <>
      <BasicNavbar />
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 5,
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Let's create your account
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={studentName}
                    name="studentName"
                    required
                    fullWidth
                    id="studentName"
                    label="Student Name"
                    autoFocus
                    onChange={handleStudentNameChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={parentName}
                    required
                    fullWidth
                    id="parentName"
                    label="Parent Name"
                    name="parentName"
                    onChange={handleParentNameChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    value={age}
                    name="age"
                    required
                    fullWidth
                    id="age"
                    label="Age of student (eg: 8)"
                    onChange={handleAgeChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={primaryLanguage}
                    required
                    fullWidth
                    id="primaryLanguage"
                    label="Language spoken"
                    name="primaryLanguage"
                    onChange={handleLanguageChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Grid item xs={12} sm={6}>
                    <label htmlFor="photo">Profile Pic </label>
                    <input
                      id="photo"
                      type="file"
                      name="profilePic"
                      onChange={handleProfilePicChange}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div class="form-floating">
                    <select
                      class="form-select"
                      id="countrySelect"
                      onChange={handleCountryChange}
                      value={country}
                    >
                      <option selected>Select Country*</option>

                      {countries?.map((country) => (
                        <option value={country}>{country}</option>
                      ))}
                    </select>
                    <label for="countrySelect">Find Your Country</label>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div class="form-floating">
                    <select
                      class="form-select"
                      id="timezone"
                      aria-label="Floating label select example"
                      value={timeZone}
                      onChange={handleTimeZoneChange}
                    >
                      <option selected>Select Timezone*</option>

                      {timezones?.map((zone) => (
                        <option value={zone}>{zone}</option>
                      ))}
                    </select>
                    <label for="country">Find your Timezone</label>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    value={email}
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    disabled
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    value={expectations}
                    fullWidth
                    id="outlined-multiline-flexible"
                    label="What you expect? (3-5 lines)"
                    name="expectations"
                    multiline
                    maxRows={3}
                    onChange={handleExpectationsChange}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end"></Grid>
            </Box>
          </Box>
        </Container>
        <ToastContainer />
      </ThemeProvider>
      <Footer />
    </>
  );
}
