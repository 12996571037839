import React, { useState } from "react";
// import './Patient.css'
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
// import './PatientProfile.css'
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../apiConfig";
import axios from "axios";
import "../Patient/PatientProfile.css";

export default function AdminProfile() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  async function changePasswordHandler(e) {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("Passwords don't match!");
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/admin/password`,
        {
          oldPassword,
          newPassword,
          confirmPassword,
        },
        { withCredentials: true }
      );
      toast.success("Password changed successfully!");
      console.log(response.data.message);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      toast.error("Error changing password!");
      console.error(error);
    }
  }

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        padding: " 0 10px",
      }}
    >
      <Toolbar />
      <Container
        maxWidth="md"
        sx={{
          mt: 4,
          mb: 4,
          backgroundColor: "#fff",
          borderRadius: "10px",
          padding: "0px 0 10px 0",
        }}
      >
        <ToastContainer />
        <form onSubmit={changePasswordHandler}>
          <div className="container rounded bg-white  mb-2">
            <div className="row">
              <div className="col-md-12 border-right">
                <div className="p-3 py-5">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="text-right">Change Password</h4>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-12">
                      <label className="labels">Current Password</label>
                      <input
                        required
                        type="password"
                        className="form-control"
                        placeholder="********"
                        name="oldPassword"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="labels">New Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="********"
                        name="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="labels">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control"
                        name="newPassword"
                        value={confirmPassword}
                        placeholder="********"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="mt-5 text-center">
                    <button
                      className="btn btn-primary profile-button"
                      // onClick={submitProfile}
                      type="submit"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-4">
            <div className="p-3 py-5">
                <div className="d-flex justify-content-between align-items-center experience"><span>Edit Experience</span><span className="border px-3 p-1 add-experience"><i className="fa fa-plus"></i>&nbsp;Experience</span></div><br/>
                <div className="col-md-12"><label className="labels">Experience in Designing</label><input type="text" className="form-control" placeholder="experience" value=""/></div> <br/>
                <div className="col-md-12"><label className="labels">Additional Details</label><input type="text" className="form-control" placeholder="additional details" value=""/></div>
            </div>
        </div> */}
            </div>
          </div>
        </form>
      </Container>
    </Box>
  );
}
