import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { BASE_URL } from "../apiConfig";
import { useCookies } from "react-cookie";

function PublicRoutesPatient() {
  const [cookies] = useCookies(["tokenP"]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    fetch(`${BASE_URL}/validatePatientToken`, {
      method: "POST",

      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        if (data.valid) {
          setIsAuthenticated(true);
          console.log(data.valid);
        } else {
          setIsAuthenticated(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsAuthenticated(false);
      });
  }, [cookies]);

  if (isAuthenticated) {
    return <Navigate to="/patient" replace />;
  }

  return (
    <>
      <Outlet />
    </>
  );
}

export default PublicRoutesPatient;
